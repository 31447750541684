import React from 'react'
import { Navigate } from 'react-router-dom'

import app from '../helpers/appGlobal'
import checkIsAdmin from '../utils/checkIsAdmin'

const LoginPrivateRoute = ({ children }) => {
  if (app.user.email) {
    if (checkIsAdmin(app.user.loginAs_Rolename)) {
      return <Navigate to="/users" />
    }
    return <Navigate to="/Dashboard" />
  }

  return children
}

export default LoginPrivateRoute
