import { encrypt, decrypt } from '../config/encrypt-decrypt'

const decryptData = data => {
  try {
    const dataObj = JSON.parse(decrypt(data))

    if (dataObj) {
      return dataObj
    }

    return {}
  } catch (e) {
    return {}
  }
}

const encryptData = data => encrypt(JSON.stringify(data))

export const getLocalStorage = key => {
  const value = localStorage.getItem(key)

  return value ? decryptData(value) : {}
}

export const setToLocalStorage = (value, key) => {
  localStorage.setItem(key, encryptData(value))
}

export const removeFromLocalStorage = key => {
  localStorage.removeItem(key)
}

export const clearLocalStorage = () => {
  localStorage.clear()
}
