// Sentry initialization should be imported first!
import './sentry'
import React from 'react'
import { createRoot } from 'react-dom/client'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'

import App from './App'
import fetchKeys from './utils/fetchKeys'
import './index.css'
import './Public/global.scss'
import { changeLang } from './utils/changeLang'
import { getDefaultLang, supportedLanguages } from './utils/getDefaultLang'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    preload: ['es'],
    supportedLngs: supportedLanguages,
    fallbackLng: 'en',
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain'
      ]
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
      requestOptions: {
        cache: 'no-store'
      }
    },
    react: {
      useSuspense: false
    }
  })

changeLang(i18n, getDefaultLang())

fetchKeys(() => {
  const root = createRoot(document.getElementById('root'))
  root.render(<App />)
})
