import moment from 'moment-timezone'
import 'moment/locale/es'

export const changeMomentTimeZoneLang = lang => {
  // and then in your function
  moment.locale(lang)
  moment.localeData(lang)
}

changeMomentTimeZoneLang('en')
