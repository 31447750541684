import { encrypt, decrypt } from '../config/encrypt-decrypt'

const decryptData = data => {
  try {
    const dataObj = JSON.parse(decrypt(data))

    if (dataObj) {
      return dataObj
    }

    return {}
  } catch (e) {
    return {}
  }
}

const encryptData = data => encrypt(JSON.stringify(data))

export const getSessionStorage = key => {
  const value = sessionStorage.getItem(key)

  return value ? decryptData(value) : null
}

export const setSessionStorage = (key, value) => {
  sessionStorage.setItem(key, encryptData(value))
}

export const removeSessionStorage = key => {
  sessionStorage.removeItem(key)
}

export const clearSessionStorage = () => {
  sessionStorage.clear()
}
