const checkIsAdmin = rolename => {
  if (rolename) {
    const name = rolename.toLowerCase()

    return name === 'admin' || name === 'superadmin'
  }

  return false
}

export default checkIsAdmin
