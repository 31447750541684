import app from '../helpers/appGlobal.js'

const {
  REACT_APP_API_URL,
  REACT_APP_API_OCP_KEY,
  REACT_APP_ANDROID_PLAYSTORE_URL,
  REACT_APP_ANDROID_APP_INSTALLED_URL,
  REACT_APP_IOS_ITUNES_URL,
  REACT_APP_IOS_APP_INSTALLED_URL,
  REACT_APP_VIDEO_URL,
  REACT_APP_EVIEW_URL,
  REACT_APP_E3_URL,
  REACT_APP_EULA_API_URL,
  REACT_APP_EPRESCRIBE_ADMIN_URL,
  REACT_APP_GOOGLE_GEOCODING_API_KEY,
  REACT_APP_AES_ENCRYPTION_KEY,
  REACT_APP_AES_ENCRYPTION_KEY_VIDEO,
  REACT_APP_WEBSOCKET_URL,
  REACT_APP_CHAT_API_URL,
  REACT_APP_CHAT_WEBSOCKET_URL,
  REACT_APP_CHAT_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
  REACT_APP_COMPANY_NAME,
  REACT_APP_PRODUCT_NAME,
  REACT_APP_PRIMARY_COLOR,
  REACT_APP_COMPANY_LOGO_URL,
  REACT_APP_ENOW_LOGO_URL,
  REACT_APP_ENCOUNTERNOW_LOGO_URL,
  REACT_APP_AZUL_PAYMENT_URL,
  REACT_APP_DISPATCHER_URL
} = import.meta.env

app.config.BASE_URL = ''
app.config.DISPATCHER_URL = REACT_APP_DISPATCHER_URL
app.config.REACT_APP_API_URL = REACT_APP_API_URL
app.config.REACT_APP_API_OCP_KEY = REACT_APP_API_OCP_KEY
app.config.REACT_APP_AES_ENCRYPTION_KEY =
  REACT_APP_AES_ENCRYPTION_KEY || 'supersecret'
app.config.REACT_APP_AES_ENCRYPTION_KEY_VIDEO =
  REACT_APP_AES_ENCRYPTION_KEY_VIDEO || '123456'
app.config.REACT_APP_GOOGLE_GEOCODING_API_KEY =
  REACT_APP_GOOGLE_GEOCODING_API_KEY
app.config.ANDROID_PLAYSTORE_URL =
  REACT_APP_ANDROID_PLAYSTORE_URL ||
  'https://play.google.com/store/apps/details?id=com.globalmed.enow&hl=en_IN&gl=US'
app.config.ANDROID_APP_INSTALLED_URL =
  REACT_APP_ANDROID_APP_INSTALLED_URL || 'encounternow://'
app.config.IOS_ITUNES_URL =
  REACT_APP_IOS_ITUNES_URL ||
  'https://apps.apple.com/in/app/encounternow/id1512051577'
app.config.IOS_APP_INSTALLED_URL =
  REACT_APP_IOS_APP_INSTALLED_URL || 'encounternow://'
app.config.APP_EVIEW_URL = REACT_APP_EVIEW_URL
app.config.APP_E3_URL = REACT_APP_E3_URL
app.config.APP_EPRESCRIBE_ADMIN_URL = REACT_APP_EPRESCRIBE_ADMIN_URL
app.config.REACT_APP_EULA_API_URL = REACT_APP_EULA_API_URL
app.config.REACT_APP_VIDEO_URL = REACT_APP_VIDEO_URL
app.config.REACT_APP_WEBSOCKET_URL = REACT_APP_WEBSOCKET_URL

app.config.REACT_APP_CHAT_API_URL = REACT_APP_CHAT_API_URL
app.config.REACT_APP_CHAT_WEBSOCKET_URL = REACT_APP_CHAT_WEBSOCKET_URL
app.config.REACT_APP_CHAT_CLIENT_ID = REACT_APP_CHAT_CLIENT_ID
app.config.REACT_APP_CLIENT_SECRET = REACT_APP_CLIENT_SECRET

app.config.IS_TOKEN_EXPIRE_ON_REFRESH = true

app.config.REACT_APP_PRIMARY_COLOR = REACT_APP_PRIMARY_COLOR || '#ff8626'
app.config.REACT_APP_COMPANY_NAME = REACT_APP_COMPANY_NAME || 'GlobalMed'
app.config.REACT_APP_PRODUCT_NAME = REACT_APP_PRODUCT_NAME || 'eNcounterNOW'

const originUrl = window.location.origin
app.config.REACT_APP_COMPANY_LOGO_URL =
  REACT_APP_COMPANY_LOGO_URL || originUrl + '/logos/GlobalMedLogo.png'
app.config.REACT_APP_ENOW_LOGO_URL =
  REACT_APP_ENOW_LOGO_URL || originUrl + '/logos/eNcounterlogo.png'
app.config.REACT_APP_ENCOUNTERNOW_LOGO_URL =
  REACT_APP_ENCOUNTERNOW_LOGO_URL || originUrl + '/logos/eNcounter_NOW.png'

app.config.REACT_APP_AZUL_PAYMENT_URL = REACT_APP_AZUL_PAYMENT_URL

const fetchKeys = cb => {
  cb()
}

export default fetchKeys
