const app = {
  locale: 'en',
  config: {},
  profile: {},
  pacs: {},
  user: {},
  stateList: {},
  geolocation: {},
  chat: {},
  channels: {},
  encounters: {},
  isApiCalledForToken: false
}

export default app
