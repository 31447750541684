import React from 'react'
import { useLocation, Navigate } from 'react-router-dom'

import app from '../helpers/appGlobal'
import checkIsAdmin from '../utils/checkIsAdmin'

const UserPrivateRoute = ({ children }) => {
  const location = useLocation()
  if (app.user.email) {
    if (!checkIsAdmin(app.user.loginAs_Rolename)) {
      return children
    }

    return <Navigate to="/users" />
  }

  return <Navigate to="/" state={location} replace />
}

export default UserPrivateRoute
