export const themeCommon = {
  palette: {
    primary: {
      main: '#3d90c9'
    }
  },
  typography: {
    subtitle1: {
      fontWeight: 'bold'
    },
    subtitle2: {
      fontWeight: 'bold'
    }
  },
  messageField: {
    MuiButton: {
      text: {
        color: 'white',
        background: 'blue'
      }
    }
  }
}
