import { registerLocale, setDefaultLocale } from 'react-datepicker'
import de from 'date-fns/locale/de'
import fr from 'date-fns/locale/fr'
import it from 'date-fns/locale/it'
import pt from 'date-fns/locale/pt'
import es from 'date-fns/locale/es'

registerLocale('de', de)
registerLocale('fr', fr)
registerLocale('it', it)
registerLocale('pt', pt)
registerLocale('es', es)

const setDatePickerLang = lang => {
  setDefaultLocale(lang)
}

setDatePickerLang('en')

export default setDatePickerLang
