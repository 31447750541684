export const supportedLanguages = ['en', 'es']

export function getDefaultLang() {
  let browserLanguage = navigator?.language

  if (navigator?.languages !== undefined) {
    [browserLanguage] = navigator.languages
  }

  [browserLanguage] = browserLanguage.split('-')

  if (supportedLanguages.includes(browserLanguage)) {
    return browserLanguage
  }

  return 'en'
}
