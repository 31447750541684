import React from 'react'
import { StyledEngineProvider } from '@mui/material/styles'

import RouterExport from './routers/routes'
import { getAndSetData } from './utils/editUpdateStorage'
import MUIWrapper from './MUIWrapper'
import GlobalCss from './GlobalCss'

const App = () => {
  getAndSetData()

  return (
    <div>
      <StyledEngineProvider injectFirst>
        <MUIWrapper>
          <GlobalCss />
          <RouterExport />
        </MUIWrapper>
      </StyledEngineProvider>
    </div>
  )
}

console.log('4.6.1')

export default App
