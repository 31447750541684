import * as Sentry from '@sentry/react'

const environments = ['qa', 'uat', 'prod']

if (environments.includes(String(import.meta.env.REACT_APP_SENTRY_ENV))) {
  Sentry.init({
    environment: import.meta.env.REACT_APP_SENTRY_ENV,
    dsn: import.meta.env.REACT_APP_SENTRY_DSN
  })
}
