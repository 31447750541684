import { useTheme } from '@mui/material'

const useThemeDetails = () => {
  const theme = useTheme()

  return {
    companyName: theme.companyName,
    btnColor: theme.primaryColor,
    eNowLogoUrl: theme.eNowLogoUrl,
    preloginLogoImg: theme.preloginLogoImg,
    productShortName: theme.productShortName,
    productLongName: theme.productLongName,
    companyLogoUrl: theme.companyLogoUrl
  }
}

export default useThemeDetails
