import React, { lazy, Suspense } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'

import PrivateRoute from './PrivateRoute'
import UserPrivateRoute from './UserPrivateRoute'
import LoginPrivateRoute from './LoginPrivateRoute'

const Loading = () => <div>Loading...</div>
const Main = lazy(async () => import('../components/Web/Main/index.js'))
const NoMatch = lazy(async () => import('../components/NoMatch/index.jsx'))
const Login = lazy(async () => import('../components/Login/login.js'))
const CreateAccount = lazy(
  async () => import('../components/Signup/CreateAccount.js')
)
const Dashboard = lazy(async () => import('../components/Dashboard.js'))
const LinkupPage = lazy(async () => import('../components/linkup/index.js'))
const VideoCall = lazy(async () => import('../components/Web/Video/App.js'))
const ChatUI = lazy(async () => import('../components/Web/Chat/index.js'))
const SentryErrorCheck = lazy(async () => import('./SentryErrorCheck.jsx'))

const SocketMsgHandler = lazy(
  async () => import('../components/Web/Websocket/SocketMsgHandler.js')
)
const PaymentPage = lazy(
  async () => import('../components/Payment/paymentPage.js')
)

const RouterExport = () => (
  <Suspense fallback={<Loading />}>
    <BrowserRouter>
      <Routes>
        <Route
          path="/institutionSettings"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/reports/:reportId"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/Dashboard"
          element={
            <UserPrivateRoute>
              <Main />
              <SocketMsgHandler />
            </UserPrivateRoute>
          }
        />
        <Route
          path="/videocall"
          element={
            <UserPrivateRoute>
              <VideoCall />
              <SocketMsgHandler isMeeting />
            </UserPrivateRoute>
          }
        />
        <Route
          path="/Chat/:evtId"
          element={
            <UserPrivateRoute>
              <ChatUI />
              <SocketMsgHandler isMeeting />
            </UserPrivateRoute>
          }
        />
        <Route
          path="/payment"
          element={
            <UserPrivateRoute>
              <PaymentPage />
            </UserPrivateRoute>
          }
        />
        <Route path="/signup" element={<CreateAccount />} />
        <Route path="/linkup" element={<LinkupPage />} />
        <Route path="/404" element={<NoMatch />} />
        <Route
          path="/"
          element={
            <LoginPrivateRoute>
              <Login />
            </LoginPrivateRoute>
          }
        />
        <Route path="/sentry-error-check" element={<SentryErrorCheck />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </BrowserRouter>
  </Suspense>
)

export default RouterExport
