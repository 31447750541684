/**
 * Summary. Encrypt Decrypt Functions
 *
 * Description. Crypto-JS AES base encryption and decruption fuctions
 *
 * @link   URL
 * @file   This files defines the   encrypt, decrypt functions.
 * @author Riyaz Ahmed.
 * @since  1.0.0
 */

import CryptoJS from 'crypto-js'

import app from '../helpers/appGlobal.js'

/**
 * Summary. Encrypt Data
 *
 * Description. Encrypt Data using key
 *
 * @since      1.0.0
 * @access     export public
 *
 * @param {string}   data           Data to be ecnrypted.
 * @param {string}   [KEY]         Encryption Key, optional. If not supplied it will use key from env. parameters.
 *
 * @return {String} Encrypted String in Hex Foramt.
 */
export const encrypt = (
  data,
  key = app.config.REACT_APP_AES_ENCRYPTION_KEY
) => {
  const b64 = CryptoJS.AES.encrypt(data, key).toString()
  const e64 = CryptoJS.enc.Base64.parse(b64)
  const eHex = e64.toString(CryptoJS.enc.Hex)

  return eHex
}

/**
 * Summary. Decrypt Data
 *
 * Description. Decrypt Data using key
 *
 * @since      1.0.0
 * @access     export public
 *
 * @param {string}   data           Data to be decrypted, in hex string format.
 * @param {string}   [KEY]         Decryption Key (suppose to be same as encryption key used for encryption), optional. If not supplied it will use key from env. parameters.
 *
 * @return {String} Decrypted String.
 */
export const decrypt = (
  data,
  key = app.config.REACT_APP_AES_ENCRYPTION_KEY
) => {
  try {
    const reb64 = CryptoJS.enc.Hex.parse(data)
    const bytes = reb64.toString(CryptoJS.enc.Base64)
    const decrypt1 = CryptoJS.AES.decrypt(bytes, key)
    const plain = decrypt1.toString(CryptoJS.enc.Utf8)

    if (plain) {
      return plain
    }

    return ''
  } catch (e) {
    return ''
  }
}
