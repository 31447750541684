import * as React from 'react'
import { Global, css } from '@emotion/react'
import useThemeDetails from './components/Common/useThemeDetails'

const GlobalCss = () => {
  const { btnColor } = useThemeDetails()

  return (
    <div>
      <Global
        styles={css`
          .btn-orange {
            background: ${btnColor} !important;
            border: 2px solid ${btnColor} !important;
          }
        `}
      />
      {/*
      <Global
        styles={{
          '.leftpanel .active .icon_text': {
            color: btnColor
          }
        }}
      />
      */}
    </div>
  )
}

export default GlobalCss
