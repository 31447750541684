import React, { createContext, useMemo, useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { themeCommon } from './Theme'
import app from './helpers/appGlobal'

/**
  TypeScript and React inconvenience:
  These functions are in here purely for types!
  They will be overwritten - it's just that
  createContext must have an initial value.
  Providing a type that could be 'null | something'
  and initiating it with *null* would be uncomfortable :)
*/
export const MUIWrapperContext = createContext({
  changeTheme: () => {}
})

export default function MUIWrapper({ children }) {
  const {
    REACT_APP_COMPANY_NAME,
    REACT_APP_PRODUCT_NAME,
    REACT_APP_PRIMARY_COLOR,
    REACT_APP_COMPANY_LOGO_URL,
    REACT_APP_ENOW_LOGO_URL,
    REACT_APP_ENCOUNTERNOW_LOGO_URL
  } = app.config
  const defaultPrimaryColor = REACT_APP_PRIMARY_COLOR
  const defaultCompanyName = REACT_APP_COMPANY_NAME
  const defaultProductName = REACT_APP_PRODUCT_NAME // Need to remove afterwards
  const defaultProductLongName = REACT_APP_PRODUCT_NAME
  const defaultCompanyLogoUrl = REACT_APP_COMPANY_LOGO_URL
  const defaultLogoUrl = REACT_APP_ENOW_LOGO_URL
  const defaultPreloginLogoImg = REACT_APP_ENCOUNTERNOW_LOGO_URL
  const [primaryColor, setPrimaryColor] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [eNowLogoUrl, setLogoUrl] = useState('')
  const [preloginLogoImg, setPreloginLogoImg] = useState('')
  const [productShortName, setProductShortname] = useState('')
  const [productLongName, setProductLongName] = useState('')
  const [companyLogoUrl, setCompanyLogoUrl] = useState('')

  const muiWrapperUtils = useMemo(
    () => ({
      changeTheme: obj => {
        setPrimaryColor(obj.primaryColor || defaultPrimaryColor)
        setCompanyName(obj.companyName || defaultCompanyName)
        setLogoUrl(obj.eNowLogoUrl || defaultLogoUrl)
        setPreloginLogoImg(obj.preloginLogoImg || defaultPreloginLogoImg)
        setProductShortname(obj.productShortName || defaultProductName)
        setProductLongName(obj.productLongName || defaultProductLongName)
        setCompanyLogoUrl(obj.companyLogoUrl || defaultCompanyLogoUrl)
      }
    }),
    []
  )

  const theme = useMemo(() => {
    document.title = productLongName
    return createTheme({
      ...themeCommon,
      primaryColor: primaryColor,
      companyName: companyName,
      eNowLogoUrl: eNowLogoUrl,
      preloginLogoImg: preloginLogoImg,
      productShortName: productShortName,
      productLongName: productLongName,
      companyLogoUrl: companyLogoUrl
    })
  }, [
    primaryColor,
    companyName,
    eNowLogoUrl,
    preloginLogoImg,
    productShortName,
    productLongName,
    companyLogoUrl
  ])

  return (
    <MUIWrapperContext.Provider value={muiWrapperUtils}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </MUIWrapperContext.Provider>
  )
}
