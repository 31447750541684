import setDatePickerLang from './setDatePickerLang'
import { changeMomentLang } from './momentLocale'
import { changeMomentTimeZoneLang } from './momentTimezoneLocale'

export const changeLang = (i18n, lang) => {
  i18n.changeLanguage(lang)
  setDatePickerLang(lang)
  changeMomentLang(lang)
  changeMomentTimeZoneLang(lang)
}
