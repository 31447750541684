import app from '../helpers/appGlobal'
import { clearSessionStorage } from './sessionStorage'
import {
  getLocalStorage,
  setToLocalStorage,
  clearLocalStorage
} from './storage'

const mainKey = 'TheePortal'

export const checkCookie = cookieName => {
  const cookieArr = document.cookie.split('; ')

  if (cookieArr?.length) {
    const sessID = cookieArr.find(row => row.startsWith(cookieName))

    return sessID
  }

  return ''
}

export const setResData = res => {
  app.user = res
}

export const updateUserData = res => {
  app.user.profilepic = res.profilepic
  app.user.user_chat_id = res.user_chat_id
  app.user.firstname = res.firstname
  app.user.lastname = res.lastname
  app.user.language = res.language
  updateToLocalStorage([
    { name: 'profilepic', value: res.profilepic },
    { name: 'user_chat_id', value: res.user_chat_id },
    { name: 'firstname', value: res.firstname },
    { name: 'lastname', value: res.lastname },
    { name: 'language', value: res.language }
  ])
}

export const setProfileData = res => {
  updateUserData(res)
  app.profile = res
}

export const setPACSData = res => {
  app.pacs = res
}

export const setToStorage = res => {
  setToLocalStorage(res, mainKey)
}

export const getLocalStorageData = () => getLocalStorage(mainKey)

export const getAndSetData = () => {
  setResData(getLocalStorageData())
}

// update specific values for user
export const updateToLocalStorage = dataArr => {
  const res = getLocalStorageData()

  dataArr.forEach(v => {
    const { name, value } = v

    app.user[name] = value
    res[name] = value
  })
  setToStorage(res)
}

export const deleteCookie = name => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 UTC;`
}

const instKey = 'eNowInst'

export const setInstIdToStorage = id => {
  if (id) {
    setToLocalStorage({ idInstitution: id }, instKey)
  }
}

export const getInstIdFromStorage = () => {
  return getLocalStorage(instKey)
}

// Logout the user and remove localstorage data
export const removeOnLogout = (isNoReload, pageName) => {
  clearLocalStorage()
  clearSessionStorage()

  app.user = {}

  if (!isNoReload) {
    let redirectUrl = `${app.config.BASE_URL}/`

    if (pageName) {
      redirectUrl = `${app.config.BASE_URL}/${pageName}`
    }

    window.location.replace(redirectUrl)
  }
}
